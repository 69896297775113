<template>
  <div class="brand-content" v-loading="formLoading">
    <el-form v-if="config" size="medium" class="small-form" :model="config" ref="config" :rules="rules"
      label-width="85px">
      <!--      <el-form-item label="缴费期限：">-->
      <!--        <el-input-number v-model="config.fee_deadline" :min="1" style="margin-left: 10px;margin-right: 20px">-->
      <!--        </el-input-number>天-->
      <!--      </el-form-item>-->
      <div class="modular">
        <p class="modular-title">缴费规则</p>
        <div class="modular-content">
          <el-form-item label="中途入会：">
            <el-radio-group class="flex-col" v-model="choose_id" @change="chooseFeeType">
              <el-radio :label="0">一次性缴纳全额会费，计算方式1（例如：当届的周期时间为：2022.05.11 ~ 2023.05.11，无论哪个时间段缴费，有效时间将在 2023.05.11 过期）
              </el-radio>
              <el-radio :label="1">一次性缴纳全额会费，计算方式2（例如：当届为 5 年一届，有效期从会员缴费日起至 5 年后结束）</el-radio>
              <el-radio :label="2">按年计算补剩余时间的会费，不足一年的按一年算（例如：当届的周期时间为：2022.05.11 ~ 2023.05.11，中途入会，不足一年的按一年计算缴费）
              </el-radio>
              <el-radio :label="3">按月计算补剩余时间的会费，不足一个月的按一个月算（例如：当届的周期时间为：2022.05.11 ~ 2023.05.11，中途入会，不足一个月的按一个月计算缴费）
              </el-radio>
              <!-- <el-radio :label="4">分期付款缴纳会费（例如：当届为 5 年一届，有效期从会员缴费日起至 5 年后结束，以 “年” 为单位分期付款缴纳全额会费）</el-radio>
              <el-radio :label="5">分期付款缴纳会费，每年递减 1000 元 <span>编辑递减金额</span> （例如：与上面分期付款缴纳会费规则一致，唯一不同的是有每年递减的金额设置）
              </el-radio> -->
            </el-radio-group>
          </el-form-item>
          <el-form-item label="多职务规则：">
            <el-radio-group class="flex-col" v-model="config.positions_rule">
              <el-radio :label="0">有多个职务时，只需缴纳其中最低的会费</el-radio>
              <el-radio :label="1">有多个职务时，只需缴纳其中最高的会费</el-radio>
              <el-radio :label="2">有多个职务时，需要分别缴纳相应的会费</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
      </div>
      <div class="modular" style="padding-top: 20px;">
        <p class="modular-title">其他设置</p>
        <div class="modular-content">
          <!-- <el-form-item label="缴费期限：">
            <el-input style="width: 86px; " type="number" v-model.number="config.fee_deadline" :min="1"
              @blur="handleMinus"></el-input>
            <span class="suffix">天</span>
          </el-form-item> -->
          <el-form-item label="会费说明：" prop="member_state">
            <el-input type="textarea" style="width: 400px;" :rows="4" placeholder="请输入" v-model="config.member_state">
            </el-input>
          </el-form-item>
          <el-form-item label="提前缴纳：" >
            <el-radio-group v-model="config.advance_fee.status">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
            <div class="fees">
              在本届结束前 
              <el-input-number style="width: 150px;" v-model="config.advance_fee.time" @change="handleChange" :min="1" label="提前缴费">
              </el-input-number>
              天提醒会员可缴费
            </div>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <FixedActionBar>
      <el-button type="primary" @click="saveConfig('config')" :loading="saveLoading">保存
      </el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import { getRule, setRule } from "../../finance/api/rule";
import FixedActionBar from "@/base/layout/FixedActionBar";
export default {
  components: { FixedActionBar },
  data() {
    return {
      formLoading: true,
      saveLoading: false,
      config: null,
      choose_id: 1,
      // 缴费时间
      payTime: 30,
      rules: {
        member_state: [
          { required: true, message: "请输入会费说明", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    getRule()
      .then((res) => {
        console.log(res)
        this.config = res.data;
        if (res.data.midway_member_fee_config.full_fee != 0) {
          this.choose_id = 0;
        }
        if (res.data.midway_member_fee_config.full_fee2 != 0) {
          this.choose_id = 1;
        }
        if (res.data.midway_member_fee_config.full_year != 0) {
          this.choose_id = 2;
        }
        if (res.data.midway_member_fee_config.full_month != 0) {
          this.choose_id = 3;
        }
        this.formLoading = false;
      })
      .catch((err) => { });
  },
  methods: {
    handleChange(value) {
      console.log(value);
    },
    handleMinus() {
      if (this.config.fee_deadline < 1) {
        this.config.fee_deadline = 1;
      }
    },
    saveConfig(formName) {
      this.$refs[formName].validate((valid) => {
        this.saveLoading = true;
        if (valid) {
          setRule({ config: this.config })
            .then((res) => {
              this.$message.success(res.msg);
              this.saveLoading = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
        }
      });
    },
    chooseFeeType(e) {
      this.config.midway_member_fee_config = {
        "full_fee": 0,
        "full_fee2": 0,
        "full_year": 0,
        "full_month": 0
      };
      let fee_type = this.config.midway_member_fee_config;
      // console.log(e);
      fee_type[Object.keys(fee_type)[e]]  = 1
      // if (e == 0) {
      //   fee_type.full_fee = 1;
      // } else if (e == 1) {
      //   fee_type.full_year = 1;
      // } else {
      //   fee_type.full_month = 1;
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.brand-content {
  margin-bottom: 80px;

  .suffix {
    line-height: 34px;
    margin-left: 12px;
    color: rgba(0, 0, 0, 0.85);
  }

  .flex-col {
    .el-radio {
      line-height: 36px;
    }
  }
  .fees{
    margin-top: 20px;
  }
}

.modular-content {
  margin-left: 11px;
}
</style>
