import api from '@/base/utils/request';

// 获取会员规则
export const getRule = () => {
    return api({
        url: '/admin/cyc/settings/getCycMemberConfigConfig',
        method: 'post'
    });
};
// 数据保存
export const setRule = data => {
    return api({
        url: '/admin/cyc/settings/setCycMemberConfigConfig',
        method: 'post',
        data
    });
};

